import {
  Box,
  Flex,
  Container,
  FormControl,
  Input,
  InputGroup,
  Link,
  Stack,
  Text,
  AbsoluteCenter,
  Divider,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/PrimaryButton";
import BrandedHeading from "../../components/BrandedHeading";
import ErrorAlert from "../../components/ErrorAlert";
import OtpModal from "./components/OtpModal";
import {
  forgotPasswordRequest,
  resetAll,
  verifyOTPRequest,
} from "./authStore/actions";
import { useSelector, useDispatch } from "react-redux";
import validator from "validator";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [email, setEmail] = useState("");
  const [fpError, setFpError] = useState("");

  const { otpSent, error, isLoading } = useSelector((state) => ({
    otpSent: state.auth.fp_otp_sent,
    error: state.auth.error,
    isLoading: state.auth.loading,
  }));
  useEffect(() => {
    dispatch(resetAll());
  }, []);

  useEffect(() => {
    if (error) {
      setFpError(error.message);
    } else setFpError("");
  }, [error]);

  useEffect(() => {
    if (otpSent) {
      onOpen();
    }
  }, [otpSent]);

  const handleForgotPasswordSubmit = () => {
    setFpError(false);
    if (email === "") {
      setFpError("Please enter email");
      return;
    } else if (!validator.isEmail(email)) {
      setFpError("Please enter valid Email address");
      return;
    }
    let payload = {
      usr: email,
      otp_type: "FP",
      source: "Web",
      user_type: "HCF",
    };
    localStorage.setItem("email", email);
    dispatch(forgotPasswordRequest(payload));
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleForgotPasswordSubmit();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [email]);

  const handleOtpSubmit = (otp) => {
    let payload = {
      usr: email,
      otp: otp,
      otp_type: "FP",
      navigate: navigate,
      redirectPath: "/change-password",
    };
    dispatch(verifyOTPRequest(payload));
  };

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center">
      <Container
        maxW="lg"
        py={{ base: "12", md: "24" }}
        px={{ base: "0", sm: "8" }}
      >
        <Stack spacing="8">
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg.surface" }}
            boxShadow="5px 5px 30px 0px rgb(224 222 222 / 89%)"
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Stack spacing="6">
              <Stack spacing="1">
                <BrandedHeading fontWeight="600" as="h2" size="md">
                  Hey There!
                </BrandedHeading>
                <BrandedHeading fontWeight="600" as="h2" size="md">
                  Forgot Your Password?
                </BrandedHeading>
                <Text fontSize="xs">
                  Follow the steps to get back to your account
                </Text>
              </Stack>
              <Stack spacing="6">
                <Stack spacing="5">
                  <FormControl>
                    {/* <FormLabel htmlFor="name">Name</FormLabel> */}
                    <InputGroup>
                      <Input
                        placeholder="Enter Registered Email"
                        id="email"
                        name="email"
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setFpError(false);
                        }}
                        required
                      />
                    </InputGroup>
                  </FormControl>
                </Stack>
                <Stack spacing="6">
                  {fpError && <ErrorAlert message={fpError} />}
                  <PrimaryButton
                    onClick={handleForgotPasswordSubmit}
                    isLoading={isLoading}
                    loadingText="Sending otp"
                    spinnerPlacement="end"
                  >
                    Next
                  </PrimaryButton>
                  <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
                    <Box position="relative" padding="10px">
                      <Divider />
                      <AbsoluteCenter bg="white" px="4">
                        OR
                      </AbsoluteCenter>
                    </Box>
                    <Text color="fg.muted">
                      <Link href="/" style={{ color: "#57ACFF" }}>
                        Click Here To Login
                      </Link>
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
      <OtpModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleOtpSubmit}
        user={email}
        otpType="FP"
      />
    </Flex>
  );
};
